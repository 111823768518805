import { getField, updateField } from 'vuex-map-fields'
import { fetchOrganizationAccountingRequest, downloadAccountingCSV } from '@/request/globalApi/requests/organizationRequests'

export default {
  namespaced: true,
  state: {
    organizationAccountingLoading: false,
    organizationAccounting: [],
    organizationAccountingId: null,
    organizationAccountingShowed: [],
    organizationInvoiceConfiguration: null,
    pagination: {
      totalItems: 0,
      firstPage: 1,
      previousPage: 1,
      nextPage: 1,
      lastPage: 1,
    },
    queryParams: {
      page: 1,
      numberOfItemsPerPage: 0,
      searchQuery: '',
      dateAfter: '',
      dateBefore: '',
      referenceOrder: '',
      dateOrder: 'desc',
      invoiceStateOrder: '',
    },
    dataTableSort: {
      sortBy: 'date',
      sortDesc: 'desc',
    },
  },
  getters: {
    getField,

    organizationAccountingInvoiceById: state => id => {
      const result = state.organizationAccountingShowed.find(
        item => item.id === id,
      )
      return result
    },
  },
  mutations: {
    updateField,

    SET_ORGANIZATION_ACCOUNTING_LOADING: (state, loading) => {
      state.organizationAccountingLoading = loading
    },

    SET_ORGANIZATION_ACCOUNTING_ID: (state, organizationId) => {
      state.organizationAccountingId = organizationId
    },

    SET_ORGANIZATION_ACCOUNTING: (state, organizationAccounting) => {
      state.organizationAccounting = organizationAccounting
    },

    SET_ORGANIZATION_ACCOUNTING_SHOWED: (state, organizationAccountInvoice) => {
      state.organizationAccountingShowed.push(organizationAccountInvoice)
    },
    CLEAR_ORGANIZATION_ACCOUNTING_SHOWED: state => {
      state.organizationAccountingShowed = []
    },

    SET_PAGINATION: (state, pagination) => {
      state.pagination = pagination
    },

    SET_SORT: (state, sort) => {
      state.sorting = sort
      state.queryParams.referenceOrder = ''
      state.queryParams.dateOrder = ''
      state.queryParams.invoiceStateOrder = ''
      if (sort.sortBy === 'state') {
        state.queryParams.invoiceStateOrder = sort.sortDesc ? 'desc' : 'asc'
      } else {
        state.queryParams[`${sort.sortBy}Order`] = sort.sortDesc
          ? 'desc'
          : 'asc'
      }
    },

    SET_PAGE(state, page) {
      state.queryParams.page = page
    },

    SET_DEFAULT_PER_PAGE: (state, perPage) => {
      state.queryParams.numberOfItemsPerPage = perPage
    },
  },
  actions: {
    exportAccounting({ state }, organizationId) {
      downloadAccountingCSV(organizationId)
    },
    fetchOrganizationAccounting({ commit, state, rootState }, payload) {
      if (
        payload.refresh
        || !state.organizationAccounting.length
        || state.organizationAccountingId === null
        || state.organizationAccountingId !== payload.organizationId
      ) {
        if (state.queryParams.numberOfItemsPerPage === 0) commit('SET_DEFAULT_PER_PAGE', rootState.appConfig.dataTable.perPage)
        commit('SET_ORGANIZATION_ACCOUNTING_LOADING', true)
        fetchOrganizationAccountingRequest(payload.organizationId, {
          ...state.queryParams,
          ...state.search,
        })
          .then(response => {
            commit('SET_ORGANIZATION_ACCOUNTING', response.data.invoices)
            commit('SET_ORGANIZATION_ACCOUNTING_ID', payload.organizationId)
            commit('SET_PAGINATION', {
              totalItems: response.data.totalItems,
              firstPage: response.data.firstPage,
              previousPage: response.data.previousPage,
              nextPage: response.data.nextPage,
              lastPage: response.data.lastPage,
            })
            commit('CLEAR_ORGANIZATION_ACCOUNTING_SHOWED')
          })
          .finally(() => {
            commit('SET_ORGANIZATION_ACCOUNTING_LOADING', false)
          })
      }
    },

    setOrganizationAccountingItemShowed(
      { commit },
      OrganizationAccountingInvoice,
    ) {
      commit(
        'SET_ORGANIZATION_ACCOUNTING_SHOWED',
        OrganizationAccountingInvoice,
      )
    },

    clearOrganizationAccountingShowed({ commit }) {
      commit('CLEAR_ORGANIZATION_ACCOUNTING_SHOWED')
    },

    setSort({ commit }, sort) {
      commit('SET_SORT', sort)
    },

    setPage({ commit }, page) {
      commit('SET_PAGE', page)
    },
  },
}
