import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import { startsWith } from 'lodash'
import i18n from '@/libs/i18n'
import * as Sentry from '@sentry/vue'
import router from "@/router/router";

let _title = ''
let _variant = 'danger'
let _text = ''

const _setNotifError4xx = error => {
  if (
    startsWith(error?.response?.data?.message, 'errors') && i18n.te(error?.response?.data?.message)
  ) {
    _title = i18n.t(error.response.data.message)
    const { errors } = error.response.data
    if (typeof errors === 'object' && !Array.isArray(errors)) {
      // Display only first sub error to prevent a big notif
      const errorKey = Object.entries(errors)[0][0]
      const errorText = i18n.te(Object.entries(errors)[0][1])
        ? i18n.t(Object.entries(errors)[0][1])
        : Object.entries(errors)[0][1]
      _text = `${errorKey}: ${errorText}`
    }
  } else {
    _title = `${i18n.t('request.status.error.client')} : ${error.response.status}`
    _text = error?.response?.data?.message || ''
  }
}

const errorHandler = error => {
  let withErrorToast = true
  switch (true) {
    case error.code === 'ECONNABORTED':
      _title = i18n.t('request.econnaborted.status')
      _text = i18n.t('request.econnaborted.statusText')
      break
    case error.code === 'ERR_CONNECTION_RESET':
      _title = i18n.t('request.err_connection_reset.status')
      _text = i18n.t('request.err_connection_reset.statusText')
      break
    case error.code === 'ERR_EMPTY_RESPONSE' || !error.response:
      _title = i18n.t('request.err_empty_response.status')
      _text = i18n.t('request.err_empty_response.statusText')
      break
    case error.response.status < 400:
      _title = `${i18n.t('request.status.redirect')} : ${error.response.status}`
      _variant = 'info'
      _text = error.response.data.message
      break
    case error.response.status === 403:
      /*_setNotifError4xx(error)
      _variant = 'warning'*/
      withErrorToast = false
      router.push({ name: 'not-authorized', query: { redirect: router.currentRoute.path } })
      break
    case error.response.status === 400 || (error.response.status > 401 && error.response.status < 500):
      _setNotifError4xx(error)
      _variant = 'warning'
      break
    case error.response.status < 600:
      _title = `${i18n.t('request.status.error.server')} : ${error.response.status}`
      _variant = 'danger'
      _text = error.response.data.message
      break
    default:
      break
  }
  if (withErrorToast) {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: _title,
        text: _text,
        icon: 'AlertTriangleIcon',
        variant: _variant,
      },
    })
  }

  Sentry.captureException(error.response.data.message)

  return error
}

export default errorHandler
