import { getField, updateField } from 'vuex-map-fields'
import { fetchOrganizationHelicoptersRequest } from '@/request/globalApi/requests/organizationHelicopterRequests'

export default {
  namespaced: true,
  state: {
    organizationHelicoptersLoading: true,
    organizationHelicopters: [],
    organizationHelicoptersShowed: [],
    pagination: {
      totalItems: 0,
      firstPage: 1,
      previousPage: 1,
      nextPage: 1,
      lastPage: 1,
    },
    queryParams: {
      page: 1,
      numberOfItemsPerPage: 10,
      modelTypeOrder: null,
      registrationOrder: null,
      callSignOrder: null,
      aocOrder: null,
      descriptionOrder: null,
      statusOrder: null,
    },
    search: {
      searchQuery: null,
    },
    sorting: {
      sortBy: 'eee',
      sortDesc: false,
    },
    selectedOrganization: JSON.parse(localStorage.getItem('selectedOrganization')) || {},
  },

  getters: {
    getField,

    organizationHelicopterById: state => id => state.organizationHelicoptersShowed.find(item => item.id === id),
  },

  mutations: {
    updateField,

    SET_SEARCH: (state, search) => {
      state.search.searchQuery = search
    },

    SET_ORGANIZATION_HELICOPTERS_LOADING: (state, loading) => {
      state.organizationHelicoptersLoading = loading
    },

    SET_ORGANIZATION_HELICOPTERS: (state, organizationHelicopters) => {
      state.organizationHelicopters = organizationHelicopters
    },

    SET_PAGINATION: (state, pagination) => {
      state.pagination = pagination
    },

    SET_SORT: (state, sort) => {
      state.sorting = sort
      state.queryParams.modelTypeOrder = null
      state.queryParams.registrationOrder = null
      state.queryParams.callSignOrder = null
      state.queryParams.aocOrder = null
      state.queryParams.descriptionOrder = null
      state.queryParams.statusOrder = null
      state.queryParams[`${sort.sortBy}Order`] = sort.sortDesc ? 'desc' : 'asc'
    },

    SET_DEFAULT_PER_PAGE: (state, perPage) => {
      state.queryParams.numberOfItemsPerPage = perPage
    },

    SET_ORGANIZATION_HELICOPTER_SHOWED: (state, organizationHelicopter) => {
      state.organizationHelicopters.push(organizationHelicopter)
    },

    CLEAR_ORGANIZATION_HELICOPTERS_SHOWED: state => {
      state.organizationHelicoptersShowed = []
    },
  },

  actions: {
    fetchOrganizationHelicopters({ commit, state, rootState }, selectedOrganization) {
      state.queryParams.numberOfItemsPerPage === 0
      && commit('SET_DEFAULT_PER_PAGE', rootState.appConfig.dataTable.perPage)
      commit('SET_ORGANIZATION_HELICOPTERS_LOADING', true)
      fetchOrganizationHelicoptersRequest(selectedOrganization.id, { ...state.queryParams, ...state.search })
        .then(response => {
          commit('SET_ORGANIZATION_HELICOPTERS', response.data.organizationHelicopters)
          commit('SET_PAGINATION', {
            totalItems: response.data.totalItems,
            firstPage: response.data.firstPage,
            previousPage: response.data.previousPage,
            nextPage: response.data.nextPage,
            lastPage: response.data.lastPage,
          })
          commit('CLEAR_ORGANIZATION_HELICOPTERS_SHOWED')
        })
        .finally(() => {
          commit('SET_ORGANIZATION_HELICOPTERS_LOADING', false)
        })
    },

    setOrganizationHelicoptersShowed({ commit }, organizationHelicopter) {
      commit('SET_ORGANIZATION_HELICOPTER_SHOWED', organizationHelicopter)
    },

    clearOrganizationHelicoptersShowed({ commit }) {
      commit('CLEAR_ORGANIZATION_HELICOPTERS_SHOWED')

      commit('SET_SEARCH', null)
      commit('SET_SORT', { sortBy: 'eee', sortDesc: false })
      commit('SET_PAGINATION', 1)
      commit('SET_DEFAULT_PER_PAGE', 10)
      commit('SET_SEARCH', null)
    },

    setSort({ commit }, sort) {
      commit('SET_SORT', sort)
    },

    setSearch({ commit }, search) {
      commit('SET_SEARCH', search)
    },
  },
}
