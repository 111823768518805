import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'
import axios from "axios";

const END_POINT = 'api/quote'
const END_POINT_CALENDAR = 'api/calendar/quote'

export const fetchQuotesRequest = params => globalApi.get(END_POINT, { params })
export const fetchQuotesCalendarRequest = params => globalApi.get(END_POINT_CALENDAR, { params })

export const fetchQuoteRequest = quoteId => globalApi.get(`${END_POINT}/${quoteId}`)
export const fetchQuoteCalendarRequest = (quoteId, params) => globalApi.get(`${END_POINT_CALENDAR}/${quoteId}`, { params })

export const postQuoteRequest = (client, message) => globalApi.post(END_POINT, client, { message })

export const patchQuoteRequest = (quote, message) => globalApi.patch(`${END_POINT}/${quote.id}`, quote, { message })

// SAVEANDSEND

export const patchQuoteSaveAndSendRequest = (quote, message) => globalApi.patch(`${END_POINT}/${quote.id}/save-and-send`, quote, { message })

// SENDINVOICE

export const postQuoteSendInvoiceRequest = (quote, message, successTitle) => globalApi.post(`${END_POINT}/${quote.id}/send-invoice`, quote, {
  message,
  successTitle,
})

// CANCELANDEDIT

export const patchQuoteCancelAndEditRequest = (quoteId, refusal, message) => globalApi.patch(`${END_POINT}/${quoteId}/cancel-and-edit`, refusal, { message })

// VALIDATE

export const postQuoteValidateRequest = (quoteId, payment, message, successTitle) => globalApi.post(`${END_POINT}/${quoteId}/validate`, payment, {
  message,
  successTitle,
})

export const patchQuoteValidateRequest = (quoteId, payment, message) => globalApi.patch(`${END_POINT}/${quoteId}/validate`, payment, { message })

// CANCEL

export const patchQuoteCancelRequest = (quoteId, refusal, message) => globalApi.patch(`${END_POINT}/${quoteId}/cancel`, refusal, { message })

// REFUSE

export const patchQuoteRefuseRequest = (quoteId, refusal, message) => globalApi.patch(`${END_POINT}/${quoteId}/refuse`, refusal, { message })

// REMOVE

export const deleteQuoteRemoveRequest = (quoteId, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed
      && globalApi.delete(`${END_POINT}/${quoteId}/remove`, { message }),
)

// CANCELORDER

export const patchQuoteCancelOrderRequest = (quoteId, refusal, message) => globalApi.patch(`${END_POINT}/${quoteId}/cancel-order`, refusal, { message })

export const patchQuoteCancelDraftRequest = (quoteId, message) => globalApi.patch(`${END_POINT}/${quoteId}/cancel-draft`, {}, { message })

// REQUESTINVOICE

export const patchQuoteRequestInvoiceRequest = (quoteId, request, message) => globalApi.patch(`${END_POINT}/${quoteId}/request-invoice`, request, {
  message,
})

// CANCELANDRECALL

export const patchQuoteCancelAndRecallRequest = (quote, message) => globalApi.patch(`${END_POINT}/${quote.id}/cancel-and-recall`, quote, {
  message,
})

// QUOTE LINES DATES

export const patchQuoteQuoteLinesDatesRequest = (quoteId, quoteLinesDates, message) => globalApi.patch(
  `${END_POINT}/${quoteId}/quote-lines-dates`,
  quoteLinesDates,
  { message },
)

// QUOTE LINES STATUS

export const patchQuoteQuoteLinesStatusRequest = (
  quoteId,
  quoteLinesStatus,
  message,
) => globalApi.patch(
  `${END_POINT}/${quoteId}/quote-lines-status`,
  quoteLinesStatus,
  { message },
)

// QUOTE REQUEST

export const postQuoteRequestRequest = (quoteRequest, message) => globalApi.post(`${END_POINT}/request`, quoteRequest, { message })

// QUOTE STATE

export const patchQuoteStateRequest = (quoteState, message) => globalApi.patch(`${END_POINT}/${quoteState.id}/update-state`, quoteState, {
  message,
})

// QUOTE TO CART

export const patchQuoteToCartRequest = (toCart, message) => globalApi.patch(`${END_POINT}/${toCart.id}/to-cart`, toCart, { message })

// FILTERS

export const fetchQuoteFiltersRequest = () => globalApi.get('/api/quote-filters')

// CLAIM

export const postQuoteClaimRequest = (quoteId, claim, message, successTitle) => globalApi.post(`${END_POINT}/${quoteId}/claim`, claim, {
  message,
  successTitle,
})

export const patchQuoteClaimRequest = (quoteId, claim, message) => globalApi.patch(`${END_POINT}/${quoteId}/claim`, claim, { message })

// CANCELLATION POLICIES

export const patchQuoteApplyCancellationPoliciesRequest = (quoteId, cancellation, message) => globalApi.patch(`${END_POINT}/${quoteId}/apply-cancellation-policies`, cancellation, { message })
export const fetchQuoteCancellationPolicyRequest = (quoteId, condition) => globalApi.get(`${END_POINT}/${quoteId}/cancellation-policy/${condition}`)

export const printPdf = (quoteId) => {
  const token = `Bearer ${localStorage.getItem('token')}` || ''
  const selectedOrganizationId = localStorage.getItem('selectedOrganization')
    && JSON.parse(localStorage.getItem('selectedOrganization')).id
  const lang = localStorage.getItem('lang')

  return axios.get(`${process.env.VUE_APP_API_BASE_URL}api/quote/${quoteId}/print`, {
    headers: { Accept: 'application/pdf', 'Content-Type': 'application/pdf', Authorization: token, selectedOrganizationId, lang },
    responseType: 'arraybuffer',
  }).then(response => {
    const blob = new Blob(
      [response.data],
      { type: 'application/pdf' },
    )
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    if (/iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      link.download = `quote-${quoteId}.pdf`
    }

    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}