export default [
  {
    path: '/organization/:tab',
    name: 'organization',
    component: () => import('@/views/organization/OrganizationWizard.vue'),
    meta: {
      title: 'navigation.organization.edit',
      action: 'ORGANIZATION_ACCOUNT_VIEW',
      breadcrumb: [
        {
          text: 'navigation.organization.edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/:id/:tab',
    name: 'editOrganization',
    component: () => import('@/views/organization/OrganizationWizard.vue'),
    meta: {
      title: 'navigation.organization.edit',
      action: 'OPERATOR_ORGANIZATION_EDIT',
      breadcrumb: [
        {
          text: 'navigation.organizations',
          to: '/organizations',
        },
        {
          text: 'navigation.organization.edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import('@/views/organization/OrganizationList.vue'),
    meta: {
      title: 'navigation.organizations',
      action: 'OPERATOR_ORGANIZATION_VIEW',
      breadcrumb: [
        {
          text: 'navigation.organization.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/accounting/invoice/:id',
    name: 'organizationAccountingInvoice',
    component: () => import('@/views/invoice/InvoiceView.vue'),
    children: [
      {
        path: 'show',
        name: 'invoiceAccountingShow',
        meta: {
          title: 'invoice.title',
          pln: 1,
          action: 'ACCOUNTING_VIEW',
          breadcrumb: [
            {
              text: 'action.show',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
    ],
  },
  {
    path: '/organization/accounting/order/:id',
    name: 'organizationAccountingOrder',
    component: () => import('@/views/order/OrderView.vue'),
    children: [
      {
        path: 'show',
        name: 'orderAccountingShow',
        meta: {
          title: 'order.title',
          pln: 1,
          action: 'ACCOUNTING_VIEW',
          breadcrumb: [
            {
              text: 'action.show',
              active: true,
            },
          ],
          backBtn: true,
        },
      },
    ],
  },
]
